const freeEmailDomains = [
    "@gmail.com",
    "@yahoo.com",
    "@outlook.com",
    "@hotmail.com",
    "@icloud.com",
    "@aol.com",
    "@protonmail.com",
    "@zoho.com",
    "@gmx.com",
    "@yandex.com",
    "@mail.com",
    "@rocketmail.com",
    "@ymail.com",
    "@live.com",
    "@msn.com",
    "@tutanota.com",
    "@fastmail.com",
    "@posteo.net",
    "@proton.me",
    "@me.com",
    "@mac.com",
    "@rediffmail.com",
    "@hushmail.com",
    "@lycos.com",
    "@excite.com",
    "@inbox.com",
    "@lavabit.com",
    "@gawab.com",
    "@bigstring.com",
    "@email.com",
    "@shortmail.com",
    "@runbox.com",
    "@safe-mail.net",
    "@eclipso.de",
    "@laposte.net",
    "@seznam.cz",
    "@qq.com",
    "@126.com",
    "@163.com",
    "@sina.com",
    "@mail.ru",
    "@inbox.ru",
    "@bk.ru",
    "@list.ru",
    "@zoznam.sk",
    "@web.de",
    "@freenet.de",
    "@arcor.de",
    "@t-online.de",
    "@orange.fr",
    "@wanadoo.fr",
    "@caramail.com",
    "@libero.it",
    "@virgilio.it",
    "@alice.it",
    "@tin.it",
    "@mail.ee",
    "@onet.pl",
    "@wp.pl",
    "@o2.pl",
    "@interia.pl",
    "@centrum.cz",
    "@volny.cz",
    "@atlas.cz",
    "@ukr.net",
    "@rambler.ru",
    "@hotmail.co.uk",
    "@hotmail.fr",
    "@live.co.uk",
    "@live.fr",
    "@btinternet.com",
    "@virginmedia.com",
    "@sky.com",
    "@talktalk.net",
    "@ntlworld.com",
    "@blueyonder.co.uk",
    "@tiscali.co.uk",
    "@plus.com",
    "@zen.co.uk",
    "@pipex.com",
    "@freeserve.co.uk",
    "@lineone.net",
    "@uk2.net",
    "@btopenworld.com",
    "@eircom.net",
    "@eir.ie",
    "@digisurf.com",
    "@charter.net",
    "@comcast.net",
    "@verizon.net",
    "@bellsouth.net",
    "@cox.net",
    "@earthlink.net",
    "@juno.com",
    "@att.net",
    "@sbcglobal.net",
    "@ameritech.net",
    "@netscape.net",
    "@dslextreme.com",
    "@frontiernet.net",
];
export default (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        return "invalid";
    }
    const domain = "@" + email.split("@")[1];
    if (freeEmailDomains.includes(domain)) {
        return "public";
    }
    return "private";
};
