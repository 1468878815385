import NodeGraphAnimation from "../NodeGraphAnimation.js";
import Fuse from "fuse.js";
import Sortable from "sortablejs";
import { debounce } from "alpinejs/src/utils/debounce.js";
import anime from "animejs/lib/anime.es.js";

export default ({ compareUrl, industries = [] }) => ({
    maxSteps: 8,
    currentStep: 1,
    _trackedFormInteractions: [],

    //--- Industry Selector Props
    industrySearchString: "",
    selectedIndustryId: null,
    industries,
    showIndustryOptions: false,
    _industryFuseInstance: null,

    //--- Priority Sorting Props
    _prioritiesSortableInstance: null,
    priorityDescriptionText: null,

    //--- Comparison Loading Props
    waitingForComparisonResults: false,
    comparisonResultsReady: false,

    get isSafariBrowser() {
        return navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") <= -1;
    },

    // --------------------------------------------------
    // Initialisation
    // --------------------------------------------------

    init() {
        // Initialise Background Animation
        if (this.$refs.nodeAnimationCanvas) {
            new NodeGraphAnimation(this.$refs.nodeAnimationCanvas, {
                backgroundColor: "#070B0F",
                nodes: {
                    max: 500,
                },
                mouse: {
                    bindTo: this.$refs.frontside,
                },
            });
        }

        // Initialise Priority Sortable
        this.initPrioritiesSortable();
        window.addEventListener(
            "resize",
            debounce(() => {
                this.initPrioritiesSortable();
            }, 500),
        );

        // Add trigger for form started analytic event
        this.$watch("showIndustryOptions", () => {
            this.trackFormInteraction("started");
        });

        // If the user clicks the input before init, we need to manually trigger the autocomplete
        if (document.activeElement === this.$refs.industryInput) {
            this.showIndustryOptions = true;
        }
    },

    initPrioritiesSortable() {
        if (null !== this._prioritiesSortableInstance) {
            this._prioritiesSortableInstance.destroy();
        }

        // On mobile views, don't allow dragging with the whole list item, as we have to use the same
        // input gesture for scrolling down the list.
        const requireDraghandle = window.innerWidth < 1024;

        this._prioritiesSortableInstance = new Sortable(this.$root.querySelector("#prioritiesList"), {
            forceAutoScrollFallback: true,
            scrollSensitivity: 30,
            scroll: true,
            filter: "li:not(.draggable)",
            onMove: (e) => {
                if (!e.dragged.classList.contains("draggable") || !e.related.classList.contains("draggable")) {
                    return false;
                }

                this.trackFormInteraction("priorities_sorted");
            },
            handle: requireDraghandle ? ".draghandle" : null,
        });
    },

    // --------------------------------------------------
    // Industry dropdown
    // --------------------------------------------------

    get suggestions() {
        if (this.industrySearchString.length === 0) {
            return this.industries;
        } else {
            const s = this.industryFuse.search(this.industrySearchString);
            return s.map((i) => i.item);
        }
    },

    get industryFuse() {
        if (null === this._industryFuseInstance) {
            this._industryFuseInstance = new Fuse(this.industries, {
                keys: ["name"],
                includeScore: true,
                minMatchCharLength: 1,
                threshold: 0.3,
            });
        }
        return this._industryFuseInstance;
    },

    async setIndustry(i) {
        this.industrySearchString = i.name;
        this.selectedIndustryId = i.id;
        this.showIndustryOptions = false;
        this.trackFormInteraction("industry_selected");
        this.nextStep(0);
    },

    // -------------------------------------------------
    // Priority Description
    // -------------------------------------------------
    showPriorityDescription(title, description) {
        // Capitalise the first letter, as we store it in lowercase
        description = description.charAt(0).toUpperCase() + description.slice(1);
        this.priorityDescriptionText = { title, description };
    },

    hidePriorityDescription() {
        this.priorityDescriptionText = null;
    },

    // --------------------------------------------------
    // Animated Header & Footer Toggling
    // --------------------------------------------------

    async toggleHeaderFooter(show) {
        const header = this.$root.querySelector("header");
        const footer = this.$root.querySelector("footer");
        const reverseIfNeeded = (arr) => (show ? arr.reverse() : arr);
        await anime
            .timeline()
            .add({
                targets: header,
                opacity: reverseIfNeeded([1, 0]),
                translateY: reverseIfNeeded([0, "-100%"]),
                duration: 300,
                easing: "linear",
            })
            .add(
                {
                    targets: footer,
                    opacity: reverseIfNeeded([1, 0]),
                    translateY: reverseIfNeeded([0, "100"]),
                    duration: 300,
                    easing: "linear",
                },
                "-=300",
            ).finished;

        if (show) {
            header.style.opacity = header.style.transform = "";
        }
    },

    async showHeaderFooter() {
        await this.toggleHeaderFooter(true);
    },

    async hideHeaderFooter() {
        await this.toggleHeaderFooter(false);
    },

    // --------------------------------------------------
    // Animated Step Navigation
    // --------------------------------------------------
    async nextStep(delay = 0) {
        // Artificial delay so the user can see their selection before we move them forward
        if (delay > 0) {
            await new Promise((resolve) => setTimeout(resolve, delay * 1000));
        }

        // Get the current and next step containers
        const currentStepContainer = this.$root.querySelector(`[data-step="${this.currentStep}"]`);
        const nextStepContainer = this.$root.querySelector(`[data-step="${this.currentStep + 1}"]`);
        const stepsContainer = this.$root.querySelector("[data-steps-container]");

        // Industry is just animated out wholesale
        if (this.currentStep === 1) {
            this.hideHeaderFooter();
            await anime({ targets: currentStepContainer, scale: 0.3, opacity: 0, duration: 250, easing: "linear" });
        } else {
            await anime
                .timeline({ easing: "linear" })
                // Stagger slide right the answers
                .add({
                    targets: currentStepContainer.querySelectorAll("li"),
                    opacity: 0,
                    translateX: 300,
                    duration: 100,
                    delay: anime.stagger(this.currentStep === 6 ? 50 : 100), // Speed up the priorities with a smaller delay
                })
                // Fade out the container
                .add(
                    {
                        targets: currentStepContainer,
                        opacity: 0,
                        duration: 500,
                    },
                    "-=500",
                ).finished;
        }

        // Locate the elements we want to animate in
        const titleEle = nextStepContainer.querySelector("[data-title]");
        const descrEle = nextStepContainer.querySelector("[data-descr]");
        const answerEles = nextStepContainer.querySelectorAll("li");

        // Activate the next step
        this.currentStep++;

        // Show the steps container if needed
        if (this.currentStep === 2) {
            nextStepContainer.style.opacity = 0;
            await anime({
                targets: stepsContainer,
                scale: [0, 1],
                opacity: [0, 1],
                duration: 500,
                easing: "easeOutExpo",
            }).finished;
            nextStepContainer.style.opacity = 1;
        }

        await anime
            .timeline({ duration: 500, easing: "linear" })
            // Slide in the title from the left
            .add({
                targets: titleEle,
                translateX: [-300, 0],
                opacity: [0, 1],
            })
            // Fade in the description
            .add({
                targets: descrEle,
                opacity: [0, 1],
            })
            // Stagger in the answer buttons from the left
            .add(
                {
                    targets: answerEles,
                    opacity: { value: [0, 1], duration: 100 },
                    translateX: {
                        value: [-300, 0],
                        easing: "easeOutElastic",
                        duration: 400,
                    },
                    delay: anime.stagger(100),
                },
                "-=500",
            ).finished;

        // Clean up after all animations are complete
        [
            currentStepContainer,
            ...currentStepContainer.querySelectorAll("li"),
            stepsContainer,
            titleEle,
            descrEle,
            ...answerEles,
        ].forEach((e) => (e.style.opacity = e.style.transform = ""));

        // Analytic event to say they completed a step
        if ("stepName" in currentStepContainer.dataset) {
            this.trackFormInteraction(currentStepContainer.dataset.stepName + "_selected");
        }
    },

    async prevStep() {
        if (this.currentStep === 1) {
            return;
        }

        const currentStepContainer = this.$root.querySelector(`[data-step="${this.currentStep}"]`);
        const prevStepContainer = this.$root.querySelector(`[data-step="${this.currentStep - 1}"]`);
        const stepsContainer = this.$root.querySelector("[data-steps-container]");

        if (this.currentStep === 2) {
            // If moving back to industry, hide the glass question container
            await anime({ targets: stepsContainer, opacity: 0, scale: 0, duration: 250, easing: "linear" }).finished;
            await this.showHeaderFooter();
        } else {
            // Otherwise, slide left the step container
            await anime({ targets: currentStepContainer, translateX: "-100%", opacity: 0, duration: 500 }).finished;
        }

        // Let alpine change what step is being displayed (x-show)
        this.currentStep--;

        // Grab the previous step container and fade it in.
        await anime({ targets: prevStepContainer, opacity: [0, 1], easing: "linear", duration: 500 }).finished;

        // Clean up after all animations are complete
        [currentStepContainer, prevStepContainer, stepsContainer].forEach(
            (e) => (e.style.opacity = e.style.transform = ""),
        );
    },

    //--------------------------------------------------
    // Comparison Submission
    //--------------------------------------------------

    async submitComparison() {
        this.trackFormInteraction("submitted");

        const form = this.$refs.comparisonForm;
        if (!form.checkValidity()) {
            form.reportValidity();
            alert("There appears to have been an issue with your selection, please refresh and try again.");
            return;
        }

        const animationComplete = this.showLoadingAnimation();

        let redirectTo = null;

        try {
            const formData = new FormData(form);
            const response = await axios.post(compareUrl, formData);
            redirectTo = response.data.url;
        } catch (e) {
            console.log(e.response);
            alert("Sorry, your comparison seems to have failed, please refresh and try again");
            return;
        }

        await animationComplete;

        window.location = redirectTo;
    },

    async showLoadingAnimation() {
        // Hide the user form
        const stepsContainer = this.$root.querySelector("[data-steps-container]");
        await anime({ targets: stepsContainer, opacity: 0, scale: 0, duration: 150, easing: "linear" }).finished;

        const checklist = this.$root.querySelector("ul#loading-checklist");

        // Grab all the checklist items, cache them and clear them.
        const lines = Array.from(checklist.querySelectorAll("li")).map((li) => {
            const loadingIcon = li.querySelector('[data-icon="loading"]');
            const completeIcon = li.querySelector('[data-icon="complete"]');
            const textEl = li.querySelector("[data-text]");
            const text = textEl.innerText;
            completeIcon.style.opacity = 0;
            textEl.innerText = "";

            return {
                el: li,
                textEl,
                loadingIcon,
                completeIcon,
                text,
            };
        });

        // Build the animation
        const timeline = anime.timeline();
        lines.forEach((line) => {
            timeline
                // Pop in the loading circle
                .add({
                    targets: line.loadingIcon,
                    opacity: [0, 1],
                    scale: [0, 1],
                    easing: "easeOutElastic(1, .5)",
                    duration: 500,
                })
                // Type out the text
                .add(
                    {
                        targets: { p: 0 },
                        p: 100,
                        round: 1,
                        update(anim) {
                            line.textEl.innerText = line.text.substring(0, Math.ceil(anim.progress));
                        },
                        easing: "linear",
                        duration: line.text.length * 75,
                    },
                    "-=500",
                )
                // Fade out the loading circle
                .add({
                    targets: line.loadingIcon,
                    opacity: [1, 0],
                    easing: "linear",
                    duration: 150,
                    delay: randomInt(0, 1000),
                })
                // Pop in the check mark
                .add({
                    targets: line.completeIcon,
                    scale: [0, 1],
                    opacity: [0, 1],
                    duration: 500,
                    easing: "easeOutElastic(1, .4)",
                });
        });

        // Finish by hiding the checklist
        timeline.add({
            targets: checklist.parentNode,
            scale: [1, 0.3],
            opacity: [1, 0],
            easing: "linear",
            duration: 300,
        });

        // Run the animation
        this.waitingForComparisonResults = true;
        await timeline.finished;

        // Display the final logo animation
        this.comparisonResultsReady = true;

        // We can trigger the redirect slightly earlier than animation end so that by the time we have the server response
        // and the page changes, the animation is complete.
        // But this doesn't work on Safari which seems to pause all animations as soon as network request is sent
        const logoAnimationDuration = this.isSafariBrowser ? 6750 : 6000;
        await new Promise((resolve) => setTimeout(resolve, logoAnimationDuration));
    },

    // --------------------------------------------------
    // The rest...
    // --------------------------------------------------

    trackFormInteraction(type) {
        // Make life easier on GA reports, let's keep the number of duplicate events down
        if (this._trackedFormInteractions.includes(type)) {
            return;
        }

        window.dataLayer.push({
            event: "compare_form_interaction",
            form_interaction: type,
        });

        this._trackedFormInteractions.push(type);
    },
});

/**
 * Generate a random integer (min & max included)
 * @param min
 * @param max
 * @returns {number}
 */
function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
