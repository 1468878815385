// Before decoupling render and update = 27 - 30% cpu
export default class NodeGraphAnimation {
    static defaultConfig = {
        backgroundColor: "#000",

        nodes: {
            density: 1.25, // Number of nodes to generate per 100px² of canvas area
            count: "auto", // Single number or for random '[130, 180]' or 'auto' to use density calculation
            max: "auto", // If appending nodes is enabled, don't go above this
            color: [68, 161, 54], //[34, 197, 72],
            radius: [3, 5],
            velocity: 1.25,
            initTime: 3, // Set to number of seconds
        },

        edges: {
            color: [68, 161, 54],
            maxLength: 250, // Nodes further apart from this will not have an edge drawn between them
        },

        mouse: {
            affectRadius: 50,
            affectVelocity: 0.4,
            bindTo: null, // Set an element to monitor for mouse events, useful when something is placed over the top
            debug: false,
        },
    };

    constructor(canvasElement, userConfig = {}) {
        // Resolve the canvas element from ID or HTMLElement
        this.canvas = typeof canvasElement === "string" ? document.querySelector(canvasElement) : canvasElement;
        if (!(this.canvas instanceof HTMLCanvasElement)) {
            throw new Error("Provided canvasElement is not a valid canvas element or ID.");
        }

        // Merge defaultConfig with userConfig
        this.config = deepMerge(NodeGraphAnimation.defaultConfig, userConfig);

        // Check the config for common issues to help the developer
        this.#checkConfig();

        // Canvas context
        this.context = this.canvas.getContext("2d", {
            alpha: false, // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas#turn_off_transparency
        });

        // Animation state
        this.running = false;

        // Mouse
        this.mouse = { x: -9999, y: -9999 };

        this.nodes = [];
        this.edges = [];

        this.needsRender = false;

        // Initialize the animation
        this.init();
    }

    init() {
        // Update the canvas size so new nodes get distributed across it
        this.#updateCanvasDimensions();

        // Start watching the canvas for resize
        this.#watchCanvasForResize();

        // Start watching when the canvas goes out of view so we can pause
        this.#watchCanvasVisibility();

        // Generate the initial set of nodes
        this.generateNodes();

        // Bind our mouse event listeners for interactivity
        this.#bindListeners();

        // Auto-start the animation
        this.startAnimation();
    }

    #checkConfig() {
        // Ensure color properties are specified as [r,g,b]
        if (!isRGBArray(this.config.nodes.color)) {
            throw new Error("Specified node.color is invalid. Format should be [255, 255, 255]");
        }

        if (!isRGBArray(this.config.edges.color)) {
            throw new Error("Specified edges.color is invalid. Format should be [255, 255, 255]");
        }
    }

    #updateCanvasDimensions() {
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
        this.canvasSizeHasChanged = false;
    }

    #watchCanvasForResize() {
        this.resizeObserver = new ResizeObserver((entries) => {
            // We don't care about checking entries, if we need to monitor size on anything else,
            // refactor this to do element tests, as 1 observer is better than multiple.
            this.canvasSizeHasChanged = true;
        });
        this.resizeObserver.observe(this.canvas);
    }

    #watchCanvasVisibility() {
        this.intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target === this.canvas) {
                    if (entry.isIntersecting && this.paused) {
                        this.startAnimation();
                        console.log("Animated resumed - back in view");
                    } else if (!entry.isIntersecting && this.running) {
                        this.stopAnimation(true);
                        console.log("Animated paused whilst out of view");
                    }
                }
            });
        });
        this.intersectionObserver.observe(this.canvas);

        // Whilst RAF won't call when tabbed out, the update loop will, so let's handle that as well
        document.addEventListener("visibilitychange", () => {
            if (document.hidden && this.running) {
                this.stopAnimation(true);
                console.log("Animated paused whilst tabbed out");
            } else {
                if (isElementInViewport(this.canvas) && this.paused) {
                    this.startAnimation();
                    console.log("Animated resumed");
                } else {
                    console.log("Animated will not resume as not in view or is paused");
                }
            }
        });
    }

    #bindListeners() {
        let listenOnEle = this.canvas;

        if (this.config.mouse.bindTo !== null) {
            listenOnEle =
                typeof this.config.mouse.bindTo === "string"
                    ? document.querySelector(this.config.mouse.bindTo)
                    : this.config.mouse.bindTo;
        }

        // Keep track of where the mouse pointer is on the canvas
        listenOnEle.addEventListener("mousemove", (e) => {
            const rect = this.canvas.getBoundingClientRect();
            this.mouse.x = e.clientX - rect.left;
            this.mouse.y = e.clientY - rect.top;
        });

        // If not on canvas, set mouse pointer coords out of the way.
        listenOnEle.addEventListener("mouseleave", (e) => {
            this.mouse = { x: -9999, y: -9999 };
        });

        // Click to add a new node
        listenOnEle.addEventListener("mousedown", (e) => {
            // If you add the node directly under the mouse, the mouse affect seems to always push them downwards
            // A slight hack to combat this, pick a random angle and offset it from the mouse
            // The distance of offset needs to be somewhat substantial, the percentage used below is pure trial & error
            // based on a 50px affect radius. Larger radius could probably reduce this percentage.

            const angle = random(0, 360); // 0 or 360 = right | 180 = left?
            const distance = this.config.mouse.affectRadius * 0.75;
            const { x, y } = moveCoords2(e.clientX, e.clientY, angle, distance);

            // console.log(e.clientX, e.clientY, x, y, angle, distance);

            this.appendNode(x, y);
        });
    }

    generateNodes() {
        let numNodes = 0;
        if (this.config.nodes.count === "auto") {
            // Density is specified as "number of dots per 100px square"
            const canvasArea = this.canvas.width * this.canvas.height;
            const hundredPxSquareArea = 100 * 100;
            numNodes = Math.round((canvasArea / hundredPxSquareArea) * this.config.nodes.density);
        } else {
            numNodes = Array.isArray(this.config.nodes.count)
                ? random(...this.config.nodes.count)
                : this.config.nodes.count;
        }

        // If max nodes is auto, we set it to +50% the number of init nodes
        if (this.config.nodes.max === "auto") {
            this.config.nodes.max = Math.ceil(numNodes * 1.5);
        }

        this.nodes = [];
        if (this.config.nodes.initTime === 0) {
            for (let i = 0; i < numNodes; i++) {
                this.nodes.push(new Node(this));
            }
        } else {
            const nps = numNodes / this.config.nodes.initTime; // nodes per second
            const np10ms = nps / 10; // nodes per 100ms
            console.log(
                "Node generation started for " +
                    numNodes +
                    " nodes at " +
                    np10ms +
                    " nodes per 100ms / " +
                    nps +
                    " nodes per second",
            );
            let interval = setInterval(() => {
                const amount = Math.min(np10ms, numNodes - this.nodes.length);
                for (let i = 0; i < amount; i++) {
                    this.nodes.push(new Node(this));
                }
                if (this.nodes.length >= numNodes) {
                    clearInterval(interval);
                    console.log("Node generation complete", numNodes);
                }
            }, 100);
        }
    }

    appendNode(x, y) {
        if (this.nodes.length >= this.config.nodes.max) {
            console.info("Append node ignored due to max limit reached");
            return;
        }
        const node = new Node(this);
        node.moveTo(x, y);
        this.nodes.push(node);
    }

    startAnimation() {
        this.#startUpdateLoop();
        this.running = true;
        this.#renderLoop(performance.now());
    }

    stopAnimation(paused = false) {
        clearInterval(this.updateInterval);
        this.running = false;
        // We set pause to know that it's auto-stopped and can be resumed whenever the reason for stopping is cleared
        // For example, stop with paused when out of view.
        this.paused = paused;
    }

    #startUpdateLoop() {
        // Limit the loop to match 60fps. This isn't a game, it doesn't need to be running at full bore.
        // Save some CPU on 120+ fps displays
        const interval = 1000 / 60;
        this.updateInterval = setInterval(() => {
            this.#updateLoop();
        }, interval);
    }

    #updateLoop() {
        // If element is not in view, fake pause
        if (!isElementInViewport(this.canvas)) {
            return;
        }

        // Tell each node to update its position
        this.nodes.forEach((node) => {
            node.update();
        });

        // Calculate the new edges for the updated positions
        this.#updateEdges();

        // Flag that we want to re-render on the next frame
        this.needsRender = true;
    }

    #updateEdges() {
        // To optimise the drawing, group the edges according to their alpha value so they can all be stroked together
        // We round to the nearest 0.05 to provide a nice fade, and limits the groups (and therefore number of stroke fills) to 20
        let alphaGroups = {};

        // Loop through the nodes twice to check every single pair
        // inner loop uses +1 to avoid duplicate pairs [a,b] === [b,a]
        for (let i = 0; i < this.nodes.length; i++) {
            for (let j = i + 1; j < this.nodes.length; j++) {
                const ni = this.nodes[i];
                const nj = this.nodes[j];
                const dx = ni.x - nj.x;
                const dy = ni.y - nj.y;
                const distance = Math.sqrt(dx * dx + dy * dy);
                if (distance < this.config.edges.maxLength) {
                    let alpha = 1 - distance / this.config.edges.maxLength; // intensity based on closeness

                    // Rounded to nearest 0.05
                    alpha = Math.round(alpha * 20) / 20;

                    // If it's now zero, skip it
                    if (alpha === 0) {
                        continue;
                    }

                    // Store it
                    if (!(alpha in alphaGroups)) {
                        alphaGroups[alpha] = [];
                    }

                    alphaGroups[alpha].push([ni.x, ni.y, nj.x, nj.y]);

                    // This is the original, less efficient code
                    // ctx.beginPath();
                    // ctx.strokeStyle = RGBArrayToString(this.config.edges.color, alpha);
                    // ctx.moveTo(this.nodes[i].x, this.nodes[i].y);
                    // ctx.lineTo(this.nodes[j].x, this.nodes[j].y);
                    // ctx.stroke();
                }
            }
        }

        this.edges = alphaGroups;
    }

    #renderLoop(timestamp = null) {
        if (!this.running) return;

        // Only draw the frame if it's in view and needs re-rendering
        if (this.needsRender) {
            this.#drawFrame();
            this.needsRender = false;
        }

        // Request the next frame
        requestAnimationFrame((ts) => this.#renderLoop(ts));
    }

    // -----------------------------------
    //  Drawing utilities
    // -----------------------------------

    #drawFrame() {
        // Check the canvas doesn't need resizing
        if (this.canvasSizeHasChanged) {
            this.#updateCanvasDimensions();
        }

        // Draw the canvas background
        this.#drawCanvas();

        // Draw edges first, so that nodes sit on top of the edge lines
        this.#drawEdges();

        // Draw the nodes
        this.#drawNodes();

        // Draw the mouse pointer circle of affect if debugging enabled
        if (this.config.mouse.debug) {
            this.#drawMousePointerArea();
        }
    }

    #drawCanvas() {
        this.context.fillStyle = this.config.backgroundColor;
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    #drawMousePointerArea() {
        this.context.beginPath();
        this.context.arc(this.mouse.x, this.mouse.y, this.config.mouse.affectRadius, 0, Math.PI * 2);
        this.context.fillStyle = "rgba(255, 0, 0, 0.25)";
        this.context.fill();
    }

    #drawNodes() {
        const color = RGBArrayToString(this.config.nodes.color, 1);
        const ctx = this.context;
        ctx.beginPath();
        this.nodes.forEach((node) => {
            ctx.moveTo(node.x, node.y);
            ctx.arc(node.x, node.y, node.radius, 0, Math.PI * 2);
        });
        ctx.fillStyle = color;
        ctx.fill();
    }

    #drawEdges() {
        const ctx = this.context;

        const alphaGroups = this.edges;

        for (const [alpha, edges] of Object.entries(alphaGroups)) {
            ctx.beginPath();

            for (let edge of edges) {
                ctx.moveTo(edge[0], edge[1]);
                ctx.lineTo(edge[2], edge[3]);
            }

            ctx.strokeStyle = RGBArrayToString(this.config.edges.color, alpha);
            ctx.stroke();
        }
    }
}

class Node {
    static COUNTER = 0;

    constructor(graph) {
        this.graph = graph;
        this.id = Node.COUNTER++;

        // Set node size, either random between [min, max] or a specific number
        this.radius = Array.isArray(this.graph.config.nodes.radius)
            ? random(...this.graph.config.nodes.radius)
            : this.graph.config.nodes.radius;

        // Give it a starting point on the canvas
        this.x = random(this.radius, this.graph.canvas.width - this.radius);
        this.y = random(this.radius, this.graph.canvas.height - this.radius);

        // Set the default velocity of this node.
        this.vx = random(this.graph.config.nodes.velocity * -1, this.graph.config.nodes.velocity);
        this.vy = random(this.graph.config.nodes.velocity * -1, this.graph.config.nodes.velocity);

        // These are modifiers
        this.vx2 = 0;
        this.vy2 = 0;
    }

    moveTo(x, y) {
        this.x = x;
        this.y = y;
    }

    update() {
        // Decay velocity modifiers until they are near zero
        if (this.vx2 !== 0) {
            if (Math.abs(this.vx2) > 0.001) {
                this.vx2 *= 0.98;
            } else {
                this.vx2 = 0;
            }
        }

        if (this.vy2 !== 0) {
            if (Math.abs(this.vy2) > 0.001) {
                this.vy2 *= 0.98;
            } else {
                this.vy2 = 0;
            }
        }

        // Move the node by adding the base velocity and any temporary modifier
        this.x += this.vx + this.vx2;
        this.y += this.vy + this.vy2;

        // If the node reaches the edge of the canvas, bounce off it
        // This accounts for the node circle size so they don't partially go off-screen before bouncing.
        const minXY = this.radius;
        const maxX = this.graph.canvas.width - this.radius;
        const maxY = this.graph.canvas.height - this.radius;
        if (this.x <= minXY || this.x > maxX) {
            this.vx *= -1;
            this.vx2 *= -1;
        }
        if (this.y <= minXY || this.y > maxY) {
            this.vy *= -1;
            this.vy2 *= -1;
        }

        // Guard against nodes that end up off-canvas, such as after a canvas resize.
        // This brings them back to the canvas edge.
        if (this.x < minXY) {
            this.x = minXY;
        }
        if (this.x > maxX) {
            this.x = maxX;
        }
        if (this.y < minXY) {
            this.y = minXY;
        }
        if (this.y > maxY) {
            this.y = maxY;
        }

        // Calc distance between this node and the mouse pointer
        // Skip if x is -9999, minor performance benefit
        if (this.graph.mouse.x !== -9999) {
            const dx = this.x - this.graph.mouse.x;
            const dy = this.y - this.graph.mouse.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            // If distance is within the affect radius, apply a velocity modifier to bounce the node away
            if (distance <= this.graph.config.mouse.affectRadius) {
                const angle = Math.atan2(dy, dx);
                this.vx2 += Math.cos(angle) * this.graph.config.mouse.affectVelocity; // Stronger effect when close to mouse
                this.vy2 += Math.sin(angle) * this.graph.config.mouse.affectVelocity;
            }
        }
    }
}

function random(min, max) {
    return Math.random() * (max - min) + min;
}

function isRGBArray(arr) {
    return (
        Array.isArray(arr) && // Must be an array
        arr.length === 3 && // Must have 3 elements
        arr.every(
            (
                value, // Check every element:
            ) =>
                Number.isInteger(value) && //   - Is an integer
                value >= 0 &&
                value <= 255, //   - Is between 0 and 255
        )
    );
}

function RGBArrayToString(arr, alpha = 1) {
    return `rgba(${arr[0]}, ${arr[1]}, ${arr[2]}, ${alpha})`;
}

function moveCoords(x, y, angle, distance) {
    // Convert angle from degrees to radians
    const angleInRadians = angle * (Math.PI / 180);

    // Calculate new coordinates
    const newX = x + Math.cos(angleInRadians) * distance;
    const newY = y + Math.sin(angleInRadians) * distance;

    return { x: newX, y: newY };
}

function moveCoords2(x, y, angleDegrees, distance) {
    // Convert the angle from degrees to radians
    const angleRadians = (angleDegrees * Math.PI) / 180;

    // Calculate the new coordinates
    const newX = x + distance * Math.cos(angleRadians);
    const newY = y + distance * Math.sin(angleRadians);

    // Return the adjusted coordinates
    return { x: newX, y: newY };
}

// function quickRound(n) {
//     return (n + (n > 0 ? 0.5 : -0.5)) << 0;
// }

// function deepMerge(obj1, obj2) {
//     const result = { ...obj1 };
//
//     for (let key in obj2) {
//         if (obj2.hasOwnProperty(key)) {
//             if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
//                 result[key] = deepMerge(obj1[key], obj2[key]);
//             } else {
//                 result[key] = obj2[key];
//             }
//         }
//     }
//     return result;
// }

function isObject(item) {
    return (
        item &&
        typeof item === "object" &&
        !Array.isArray(item) &&
        [null, Object.prototype].includes(Object.getPrototypeOf(item))
    );
}

function deepMerge(target, source) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target)) Object.assign(output, { [key]: source[key] });
                else output[key] = deepMerge(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();

    return rect.top < window.innerHeight && rect.left < window.innerWidth && rect.bottom > 0 && rect.right > 0;
}
