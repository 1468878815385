import { Livewire } from "../../../vendor/livewire/livewire/dist/livewire.esm";

export default class SessionTokenSyncroniser {
    constructor() {
        this.id = Math.random().toString(16).slice(2);
    }

    init() {
        this.#openBroadcastChannel();
        this.#listenForLivewireEvent();
        this.#triggerInitEvent();
    }

    #triggerInitEvent() {
        window.dispatchEvent(new CustomEvent("SessionTokenSyncroniser:initialised"));
    }

    // Create a Broadcast channel for comms across tabs, we use this to pass around the crsf token changes if someone logs in
    // via a second tab, avoiding the comparison form submission (for example) failing crsf verification.
    #openBroadcastChannel() {
        const broadcastChannel = new BroadcastChannel("sts_channel");
        broadcastChannel.onmessage = (e) => {
            const { data = {} } = e;
            const { event = null } = data;
            console.log("STS New Token dete", data);
            if (event === "session_regenerated" && "csrf_token" in data) {
                console.log("[" + this.id + "] Received STS Token", data.csrf_token);
                this.updateCsrfToken(data.csrf_token, false);
            } else {
                console.error("[" + this.id + "] Unrecognised STS message", data);
            }
        };
        this.broadcastChannel = broadcastChannel;
    }

    // Update the CSRF token when you login on the comparison form
    #listenForLivewireEvent() {
        document.addEventListener("livewire:init", () => {
            Livewire.on("sessionRegenerated", (csrfToken) => {
                this.updateCsrfToken(csrfToken, true);
            });
        });
    }

    updateCsrfToken(token, broadcast = false) {
        window.livewireScriptConfig.csrf = token;
        document.head.querySelector('meta[name="csrf-token"]').setAttribute("content", token);
        if (broadcast) {
            console.log("[" + this.id + "] Broadcasting STS Token", token);
            this.broadcastChannel.postMessage({ event: "session_regenerated", csrf_token: token });
        }
    }
}
